<template>
  <div class="space-create fill-height">
    <v-container fluid>
      <c-ecosystem-nav :ecosystemId="ecosystemId"></c-ecosystem-nav>

      <v-container class="space-create__content">
        <v-row mb-2>
          <v-col class="d-flex flex-column pa-0">
            <button
              class="mt-10 back-button-container"
              @click="$router.go(-1)"
              data-cy="backButton"
            >
              <v-icon color="primary">mdi-chevron-left</v-icon>
              <span class="back-button-text"> {{ $t('spaces.create.back') }} </span>
            </button>
            <div style="display: flex">
              <c-page-heading
                class="heading"
                :heading="$t('spaces.create.create')"
                :subHeading="$t('spaces.create.space')"
              ></c-page-heading>
              <div v-if="form.name" class="heading space-name-container">
                <h1 class="space-name-content">
                  <span style="font-weight: 200; color: #1f2329" data-cy="space-name-header">
                    : <span style="color: #807ef0">{{ form.name }}</span>
                  </span>
                </h1>
              </div>
            </div>

            <span
              class="subheading"
              data-cy="createANewSpaceForYourTeamARegularMeetingOrSpecialEvent"
              >{{
                $t('spaces.create.createANewSpaceForYourTeamARegularMeetingOrSpecialEvent')
              }}</span
            >

            <!-- Content -->
            <v-row>
              <v-col sm="12">
                <div>
                  <m-form ref="form" data-cy="space-form">
                    <m-tabs v-model="tab">
                      <m-tab data-cy="detailsTab" href="#details">
                        {{ $t('spaces.create.details') }}
                      </m-tab>
                      <m-tab data-cy="attendeesTab" href="#attendees">
                        {{ $t('spaces.create.attendees') }}
                      </m-tab>
                      <m-tab data-cy="brandingTab" href="#branding" v-if="showBrandingTab">
                        {{ $t('spaces.create.branding') }}
                      </m-tab>
                    </m-tabs>

                    <m-tabs-items v-model="tab">
                      <m-tab-item value="details">
                        <c-space-details-tab
                          :ecosystemId="ecosystemId"
                          :spaceId="spaceId"
                          v-model="form"
                        ></c-space-details-tab>
                      </m-tab-item>
                      <m-tab-item value="attendees">
                        <c-space-attendees-tab
                          v-model="form.attendees"
                          :hideBulkAction="tab === 0"
                          :ecosystem="ecosystem"
                          @bulk-action-panel:update="toggleBulkActionPanel"
                        />
                      </m-tab-item>
                      <m-tab-item value="branding">
                        <c-space-branding-tab
                          :ecosystem="ecosystem"
                          :space="space"
                          :templateId="form.templateId"
                          :templateName="templateName"
                          @setBranding="setBranding"
                        />
                      </m-tab-item>
                    </m-tabs-items>

                    <template #footer="{ invalid, dirty }">
                      <!-- Teleport action bar to the bottom of the page -->
                      <portal v-if="showBulkActionPanel" selector="#routerViewport">
                        <m-action-bar>
                          <template #action>
                            <m-button
                              data-cy="submit-button"
                              :disabled="!dirty || invalid"
                              :label="
                                spaceId
                                  ? $t('spaces.create.saveChanges')
                                  : $t('spaces.create.createSpace')
                              "
                              @click="onSubmit"
                            ></m-button>
                          </template>
                        </m-action-bar>
                      </portal>
                    </template>
                  </m-form>
                </div>
              </v-col>
            </v-row>

            <m-loading
              type="circle"
              :loading="creating"
              :title="$t('spaces.create.loading.title')"
              :subTitle="$t('spaces.create.loading.subTitle')"
            ></m-loading>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
<script>
import {
  MTabs,
  MTab,
  MTabItem,
  MTabsItems,
  MForm,
  MActionBar,
  MButton,
  MLoading,
} from 'gatherings-storybook'
import { Portal } from '@linusborg/vue-simple-portal'
import UnsavedChangesMixin from '@/mixins/UnsavedChangesMixin'
import { sleep } from '@/utilities/functions'

export default {
  mixins: [UnsavedChangesMixin],
  props: ['ecosystemId'],
  components: { MTabs, MTab, MTabItem, MTabsItems, MForm, MActionBar, MButton, Portal, MLoading },
  data: () => ({
    tab: null,
    spaceId: null,
    form: {
      name: '',
      templateId: '',
      isEcosystemOpen: true,
      attendees: [],
      branding: [],
    },
    checkedUsers: [],
    showBulkActionPanel: true,
    creating: false,
  }),
  computed: {
    space() {
      return this.spaceId
        ? this.$store.getters['ecosystems/getSpaceById'](this.ecosystemId, this.spaceId)
        : null
    },
    ecosystem() {
      return this.$store.getters['ecosystems/getById'](this.ecosystemId)
    },
    showBrandingTab() {
      return this.space
    },
    templateName() {
      let match = this.spaceTemplates?.find(template => template.value === this.form.templateId)
      return match ? match.text : ''
    },
    spaceTemplates() {
      return this.$store.getters['spaces/templates'].map(g => {
        return {
          value: g.id,
          text: g.name,
        }
      })
    },
  },
  async mounted() {
    this.fetchEcosystem()

    await this.$store.dispatch('spaces/fetchTemplates')

    this.watchForUnsavedChanges('form', this.onUnsavedChanges, {
      title: this.$t('spaces.unsavedChanges.title'),
      subMessage: this.$t('spaces.unsavedChanges.subMessage'),
      buttons: [
        {
          label: this.$t('spaces.unsavedChanges.buttons.dontSave'),
          type: 'cancel',
          variant: 'secondary',
        },
        {
          label: this.$t('spaces.unsavedChanges.buttons.save'),
          type: 'confirm',
          icon: 'chevron-right-stroke',
          iconRight: true,
        },
      ],
    })
  },
  methods: {
    async fetchEcosystem() {
      await this.$store.dispatch('ecosystems/fetchById', this.ecosystemId)
      await this.$store.dispatch('ecosystems/fetchUsers', this.ecosystemId)
    },
    async onSubmit() {
      this.creating = true

      const data = {
        name: this.form.name,
        templateId: this.form.templateId,
        isWorkspaceOpen: this.form.isEcosystemOpen,
        attendees: this.form.attendees.map(attendee => ({
          id: attendee.id,
          givenName: attendee.givenName,
          surname: attendee.surname,
          email: attendee.email,
          company: attendee.company,
          jobTitle: attendee.jobTitle,
          host: false,
        })),
      }

      // Edit or Create
      const action = this.spaceId ? 'ecosystems/updateSpace' : 'ecosystems/createSpace'

      if (this.spaceId) {
        data.id = this.spaceId

        try {
          await this.$store.dispatch('ecosystems/updateSpaceStyle', {
            ecosystemId: this.ecosystemId,
            spaceId: this.spaceId,
            data: { styles: this.form.branding },
          })
        } catch (e) {
          this.showMessage(this.$t('errors.sorrySomethingWentWrong'), false)
        }
      }

      try {
        const space = await this.$store.dispatch(action, {
          ecosystemId: this.ecosystemId,
          data,
        })

        this.spaceId = space.id

        await sleep(2000)

        // Ensure the unsaved changes modal isn't triggered again
        this.resetUnsavedChanges()
      } catch (error) {
        console.error(error)
        this.showMessage(this.$t('errors.sorrySomethingWentWrong'), false)
        this.creating = false
        return
      }

      let message = action === 'ecosystems/updateSpace' ? 'spaceUpdated' : 'spaceCreated'
      this.showMessage(this.$t(`spaces.create.${message}`))

      // Wait some time so the user can read the message
      this.creating = false

      this.$router.push(`/ws/${this.ecosystemId}/spaces`)
    },
    toggleBulkActionPanel(value) {
      this.showBulkActionPanel = !value
    },
    showMessage(message, success = true) {
      this.$store.dispatch('ui/launchSnackbar', {
        color: success ? 'green' : 'error',
        message: message,
        buttonColor: 'white',
      })
    },
    /**
     * A function that is called when the user attempts to navigate away from the current page with unsaved changes
     * @param {string} result either 'confirm' | 'cancel'
     * @param {*} next call to continue navigating to the next route
     */
    async onUnsavedChanges(result, next) {
      // The user just wants to exit
      if (result === 'cancel') {
        next()
        return
      }

      const { validate } = this.$refs.form.validator
      const isValid = await validate()

      // The user wants to save change, validate the form before attempting to save
      if (!isValid) {
        // Can't submit so just prevent them from navigating away
        return
      }

      // Save changes
      await this.onSubmit()

      // Continue navigation
      next()
    },
    setBranding(branding) {
      this.$refs.form.markAsDirty()
      this.form.branding = branding
    },
  },
}
</script>
<style scoped lang="scss">
.space-create {
  display: flex;
  flex-direction: column;

  .container:not(.action-bar) {
    height: 100%;
  }
}

.space-create__content {
  :deep(*) {
    font-family: Mulish !important;
  }
  // TODO: Update vuetify breakpoints variable to use these breakpoint settings
  @media (min-width: 960px) {
    max-width: 840px;
  }
  @media (min-width: 1264px) {
    max-width: 1120px;
  }
  @media (min-width: 1904px) {
    max-width: 1240px;
  }
  form.v-form {
    margin: -16px;
  }
}
.heading {
  margin-top: 32px !important;
}
.space-name-container {
  max-width: 65%;
  white-space: nowrap;
}
.space-name-content {
  font-size: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #807ef0;
}
.subheading {
  margin-top: -48px;
  margin-bottom: 32px;
  font-size: 1.285rem;
  line-height: 1.5;
}
.back-button-container {
  display: flex;
  align-items: center;
  max-width: fit-content;
  margin-left: -0.375rem;
  cursor: pointer;
}
.back-button-text {
  color: #807ef0;
  font-weight: 700;
  font-size: 1.1429rem;
  line-height: 24px;
}
.tab-heading {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: #363332;
  text-transform: none;
}
.tabs {
  border-bottom: 1px solid #dce2e9;
}
</style>
